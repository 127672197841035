import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../utils/axios';
import Api from '../utils/apiUrls';
import { InterviewsState } from '../model/interviews.model';
// config
import { PAGINATION_LINK } from '../config-global';

const initialState: InterviewsState = {
  isLoading: false,
  interviewsData: [],
  interviewStatus: [],
  interviewDetails: [],
  interviewRounds: [],
  interviewRoundDetails: {},
  interviewProfileDetails: {},
  locations: [],
  error: {},
  totalData: 0,
  canAddMoreRounds: 0
};

export const interviewsSlice = createSlice({
  name: 'interviews',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setInterviewsList(state, action) {
      const { interviews, total_count } = action.payload;
      state.interviewsData = interviews;
      state.totalData = total_count;
    },
    setInterviewDetails(state, action) {
      state.interviewDetails = action.payload.interviews;
    },
    setInterviewRoundsList(state, action) {
      const { interview_rounds, can_add_round, total_count } = action.payload;
      state.interviewRounds = interview_rounds;
      state.canAddMoreRounds = can_add_round;
      state.totalData = total_count;
    },
    setInterviewRoundDetails(state, action) {
      state.interviewRoundDetails = action.payload
    },
    setLocationList(state, action) {
      state.locations = action.payload
    },
    setInterviewProfileDetails(state, action) {
      state.interviewProfileDetails = action.payload
    },
    setInterviewsError(state, action) {
      state.error = action.payload;
    },
  },
});
export const {
  setLoading,
  setLocationList,
  setInterviewsList,
  setInterviewDetails,
  setInterviewRoundsList,
  setInterviewRoundDetails,
  setInterviewProfileDetails,
  setInterviewsError,
} = interviewsSlice.actions;

export default interviewsSlice.reducer;
const handleAsyncThunk = async (dispatch: any, request: any, thunkAction?: any) => {
  try {
    dispatch(setLoading(true));
    const response = await request();
    if (thunkAction) dispatch(thunkAction(response.data.data));
    dispatch(setLoading(false));
    return response;
  } catch (error) {
    dispatch(setLoading(false));
    if (error?.response?.status) {
      dispatch(setInterviewsError(error.message));
    } else {
      const errorMessages = Object.values(error?.data).flat();
      dispatch(setInterviewsError(errorMessages));
    }
    throw error;
  }
};

export const fetchInterviewList = createAsyncThunk(
  'interviews/fetchInterviewList',
  async (
    {
      page = 0,
      employeeId = '',
      searchTerm = '',
      teamId = '',
      sort = '',
      sortKey = ''
    }: { page?: number; employeeId?: string; searchTerm?: string, teamId?: string, sort?: string, sortKey?: string },
    { dispatch }
  ) => {

    const params = {
      user_uuid: employeeId,
      vt_team_uuid: teamId,
      search_term: searchTerm,
      skip: `${page * 8}`,
      limit: PAGINATION_LINK.PER_PAGE_COUNT,
      sort,
      sort_key: sortKey
    };

    const request = () => axios.get(Api.interviews + Api.list, { params });
    await handleAsyncThunk(dispatch, request, setInterviewsList);

  }
);

export const fetchInterviewRounds = createAsyncThunk(
  'interviews/fetchInterviewRounds',
  async (interviewUUID: string, { dispatch }) => {
    const params = { interview_uuid: interviewUUID };
    const request = () => axios.get(Api.interviews + Api.allRounds, { params });
    await handleAsyncThunk(dispatch, request, setInterviewRoundsList);
  }
);

export const fetchInterviewProfileDetails = createAsyncThunk(
  'interviews/fetchInterviewProfileDetails',
  async (interviewUUID: string, { dispatch }) => {
    const request = () => axios.get(`${Api.interviews}${Api.interviewProfile}/${interviewUUID}`);
    await handleAsyncThunk(dispatch, request, setInterviewProfileDetails);
  }
);

export const fetchInterviewRoundDetails = createAsyncThunk(
  'interviews/fetchInterviewRoundDetails',
  async (interviewRoundUUID: string, { dispatch }) => {
    const request = () => axios.get(`${Api.interviews}${Api.roundDetails}/${interviewRoundUUID}`);
    await handleAsyncThunk(dispatch, request, setInterviewRoundDetails);
  }
);

export const fetchInterviewDetails = createAsyncThunk(
  'interviews/fetchInterviewDetails',
  async (employeeId: string, { dispatch }) => {
    const request = () => axios.get(`${Api.interviews}${Api.details}/${employeeId}`);
    await handleAsyncThunk(dispatch, request, setInterviewDetails);
  }

);

export const editInterview = createAsyncThunk(
  'interviews/editInterview',
  async (data: any, { dispatch }) => {
    const request = () => axios.post(Api.interviews + Api.edit, data);
    const response = await handleAsyncThunk(dispatch, request);
    return { success: true, response: response?.data };
  }
);

export const addInterview = createAsyncThunk(
  'interviews/addInterview',
  async (data: any, { dispatch }) => {
    const request = () => axios.post(Api.interviews + Api.add, data);
    const response = await handleAsyncThunk(dispatch, request);
    return { success: true, response: response?.data };
  }
);

export const addRound = createAsyncThunk(
  'interviews/addRound',
  async (data: any, { dispatch }) => {
    const request = () => axios.post(Api.interviews + Api.addRound, data);
    const response = await handleAsyncThunk(dispatch, request);
    return { success: true, response: response?.data };
  }
);

export const addFeedback = createAsyncThunk(
  'interviews/addFeedback',
  async (data: any, { dispatch }) => {
    const request = () => axios.post(Api.interviews + Api.feedback, data);
    const response = await handleAsyncThunk(dispatch, request);
    return { success: true, response: response?.data };
  }
);

export const deleteInterview = createAsyncThunk(
  'interviews/deleteInterview',
  async (data: any, { dispatch }) => {
    const request = () => axios.post(Api.interviews + Api.delete, data);
    const response = await handleAsyncThunk(dispatch, request);
    return { success: true, response: response?.data };
  }
);

export const deleteInterviewRound = createAsyncThunk(
  'interviews/deleteInterview',
  async (data: any, { dispatch }) => {
    const request = () => axios.post(Api.interviews + Api.deleteRound, data);
    const response = await handleAsyncThunk(dispatch, request);
    return { success: true, response: response?.data };
  }
);

export const fetchLocationList = createAsyncThunk(
  'interviews/fetchLocationList',
  async (_, { dispatch }) => {
    const request = () => axios.get(Api.locations);
    await handleAsyncThunk(dispatch, request, setLocationList);
  }
);
